import React, { useState, useEffect } from 'react'

export const myContext = React.createContext()

const Provider = (props) => {
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    if (!language)
      setLanguage(window.localStorage.getItem('language') || 'english')
  })

  return (
    <myContext.Provider
      value={{
        language: language || 'english',
        changeLanguage: (lang) => {
          window.localStorage.setItem('language', lang)
          setLanguage(lang)
        },
      }}
    >
      {props.children}
    </myContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
